import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chart"];

  connect() {
    var endpoint = this.data.get("endpoint");
    this.load(endpoint);
  }

  load(endpoint) {
    let railsUJS = require("@rails/ujs");
    var chart = this.chartTarget;
    railsUJS.ajax({
      url: endpoint,
      type: "get",
      success: function (data, textStatus, jqXHR) {
        chart.innerHTML = jqXHR.response;
      },
    });
  }
}
