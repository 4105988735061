import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["select", "name"]
  
  change() {
    var id = this.selectTarget.value
    location.href = "/races/new?id=" + id + "&race_name=" + this.nameTarget.value
  }
}