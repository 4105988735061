import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["dropdown", "button"]
    
    change() {
      if (this.dropdownTarget.value.length > 0) {
        this.buttonTarget.disabled = false;
        this.setActiveButtonStyle()
      }
      else {
        this.buttonTarget.disabled = true;
        this.setDisabledButtonStyle()
      }
    }
    
    setActiveButtonStyle() {
      this.buttonTarget.classList.add("cursor-pointer", "hover:opacity-75");
      this.buttonTarget.classList.remove("opacity-25");
    }
    
    setDisabledButtonStyle() {
      this.buttonTarget.classList.remove("cursor-pointer", "hover:opacity-75");
      this.buttonTarget.classList.add("opacity-25");
    }
}