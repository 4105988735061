import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["container"]

    close(event) {
      event.preventDefault();
      this.containerTarget.classList.add('ease-in', 'duration-100')
  
      // Trigger transition
      setTimeout(() => {
        this.containerTarget.classList.add('opacity-0');
      }, 100);
  
      // Remove element after transition
      setTimeout(() => {
        this.containerTarget.remove();
      }, 300);
    }
}