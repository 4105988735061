import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filter", "order"]
    
  change() {
    if (this.hasFilterTarget) {
      var type_id = this.filterTarget.value;
      var order_by = this.getParam("order_by");
    }
    else {
      var order_by = this.orderTarget.value;
      var type_id = this.getParam("type_id");
    }
    location.href = "/activities?type_id=" + type_id + "&order_by=" + order_by;
  }
  
  getParam(param) {
    var url_string = window.location.href
    var url = new URL(url_string);
    var value = url.searchParams.get(param);
    return value || ""
  }
}