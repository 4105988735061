import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["overlay", "menu"]

    open(event) {
      event.preventDefault()
      this.overlayTarget.classList.remove("hidden");
      this.menuTarget.classList.remove("hidden");
    }
  
    close(event) {
      event.preventDefault();
      this.overlayTarget.classList.add("hidden");
      this.menuTarget.classList.add("hidden");
    }
}