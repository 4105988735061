import { Controller } from "stimulus";

export default class extends Controller {  
  show(event) {
    var label = this.getLabel(event)
    label.style.display = "block";
  }

  hide(event) {
    var label = this.getLabel(event)
    label.style.display = "none";
  }
  
  getLabel(event) {
    var labelId = event.target.dataset.id
    var label = document.getElementById(labelId);
    return label
  }
}
