import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggle" ]

  toggle(event) {
    var value = event.currentTarget.checked    
    document.getElementById("monthly_billing").classList.toggle("hidden", value);
    document.getElementById("yearly_billing").classList.toggle("hidden", !value);
    
    document.getElementById("monthly_billing_button").classList.toggle("hidden", value);
    document.getElementById("yearly_billing_button").classList.toggle("hidden", !value);
  }
}