import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["badge"];

  connect() {
    this.numberOfChecks = 0;
    
    this.check();
    
    if (this.data.has("refreshInterval") && this.data.get("pollingActive") == "true") {
      this.startRefreshing()
    }
  }
  
  disconnect() {
    this.stopRefreshing()
  }

  check() {
    if (this.numberOfChecks < 4) {
      fetch('/imports/count_pending.json')
        .then((response) => response.json())
        .then((json) => {
          if (json > 0) {
            this.badgeTarget.classList.remove("hidden");
          }
          else {
            this.badgeTarget.classList.add("hidden");
          }
            
        });
    }
    this.numberOfChecks +=1 ;
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.check()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}