import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output", "previous", "diff", "trend", "iconup", "icondown"];

  connect() {
    var endpoint = this.data.get("endpoint");
    this.load(endpoint);
  }

  load(endpoint) {
    fetch(endpoint)
      .then((response) => response.json())
      .then((json) => {
        this.outputTarget.textContent = json.value;
        this.previousTarget.textContent = json.previous;
        if (json.trend == "pos") {
          this.trendTarget.classList.add("text-teal-500");
          this.diffTarget.textContent = "+" + json.diff;
          this.iconupTarget.classList.remove("hidden");
        } else {
          this.trendTarget.classList.add("text-orange-600");
          this.diffTarget.textContent = "-" + json.diff;
          this.icondownTarget.classList.remove("hidden");
        }
      });
  }
}
