import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["duration", "distance", "activities", "chart"]

    connect() {
        this.durationTarget.classList.toggle("hidden", false)
    }

    toggle(event) {
        event.preventDefault()
        this.hideAll()
        const value = event.target.dataset.value
        const target = eval("this." + value + "Target")
        target.classList.toggle("hidden", false)
    }

    hideAll() {
        this.chartTargets.forEach((elem) => { elem.classList.toggle("hidden", true) })
    }
}