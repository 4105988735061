import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['key',  "plan"]
  
  connect() {
    const plan = this.planTarget.dataset.plan;
    const key = this.keyTarget.dataset.key
    
    fetch("/checkout/new?plan=" + plan)
      .then(response => response.json())
        .then((json) => {
          var stripe = Stripe(key);
          stripe.redirectToCheckout({
            sessionId: json.session_id
          })
        .then(function (result) {
      });
    });  
  }
}
