import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["select"]
    
  change() {
    var value = this.selectTarget.value 
    Rails.ajax({
      url: "/settings/change_unit?value=" + value,
      type: "put"
    });
  }
}