import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    var endpoint = this.data.get("endpoint");
    this.load(endpoint);
  }

  load(endpoint) {
    fetch(endpoint)
      .then((response) => response.json())
      .then((json) => {
        this.outputTarget.textContent = json.value;
      });
  }
}
