import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "topbar" ]
  
  connect() {
    
    this.detectScroll()
  }
  
  detectScroll() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 100) {
        // byt färg på topbar
        console.log("---> byt färg");
        document.getElementById("topbar").classList.toggle("bg-peppblue-900", true);
        document.getElementById("topbar").classList.toggle("text-teal-400", true);
        document.getElementById("topbar").classList.toggle("border-b", true);
        
        document.getElementById("logo").classList.toggle("text-teal-400", true);
      } else {
        // växla tillbaka till ursprungsfärg
        document.getElementById("topbar").classList.toggle("bg-peppblue-900", false);
        document.getElementById("topbar").classList.toggle("text-teal-400", false);
        document.getElementById("topbar").classList.toggle("border-b", false);
        
        document.getElementById("logo").classList.toggle("text-teal-400", false);
      }
    }
  }
}

